import * as React from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { Box } from "@mui/material"
import "./YearsExperience.scss"

const YearsExperience = () => {
  const { t } = useTranslation()
  const mifecha = moment("04/01/2016", "MM/DD/YYYY").fromNow().split(" ")[0]

  return (
    <div>
      <Box mt={5} mb={5} sx={{ textAlign: "center" }}>
        <h1 className="yearsExperienceText">
          {mifecha}+ {t("home.yearsofexperience")}
        </h1>
      </Box>
    </div>
  )
}

export default YearsExperience
