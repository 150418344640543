import * as React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Grid } from "@mui/material"
import "./SkillsExperience.scss"

const SkillsExperience = () => {
  const { t } = useTranslation()

  return (
    <div className="root">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box width="250px" m={1} className="boxSkills">
          <StaticImage
            src="../../images/coding2.png"
            width={50}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Full Stack Development"
            placeholder="tracedSVG"
          />
          <h6 className="skillsDescription">
            {t("home.experiencecards.fullstackdevelopment")}
          </h6>
        </Box>
        <Box width="250px" m={1} className="boxSkills">
          <StaticImage
            src="../../images/translate.png"
            width={50}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Internationalization"
            placeholder="tracedSVG"
          />
          <h6 className="skillsDescription">
            {t("home.experiencecards.internationalization")}
          </h6>
        </Box>
        <Box width="250px" m={1} className="boxSkills">
          <StaticImage
            src="../../images/api.png"
            width={50}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="API Endpoints Integration"
            placeholder="tracedSVG"
          />
          <h6 className="skillsDescription">
            {t("home.experiencecards.apiendpointsintegration")}
          </h6>
        </Box>
        <Box width="250px" m={1} className="boxSkills">
          <StaticImage
            src="../../images/seo.png"
            width={50}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="SEO Implementation"
            placeholder="tracedSVG"
          />
          <h6 className="skillsDescription">
            {t("home.experiencecards.seoimplementation")}
          </h6>
        </Box>
        <Box width="250px" m={1} className="boxSkills">
          <StaticImage
            src="../../images/icons8-code-fork-512.png"
            width={50}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Code Versioning"
            placeholder="tracedSVG"
          />
          <h6 className="skillsDescription">
            {t("home.experiencecards.codeversioning")}
          </h6>
        </Box>
        <Box width="250px" m={1} className="boxSkills">
          <StaticImage
            src="../../images/flow-chart.png"
            width={50}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Agile Methodologies"
            placeholder="tracedSVG"
          />
          <h6 className="skillsDescription">
            {t("home.experiencecards.agilemethodologies")}
          </h6>
        </Box>
        <Box width="250px" m={1} className="boxSkills">
          <StaticImage
            src="../../images/responsive.png"
            width={50}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Responsive Design"
            placeholder="tracedSVG"
          />
          <h6 className="skillsDescription">
            {t("home.experiencecards.responsivedesign")}
          </h6>
        </Box>
        <Box width="250px" m={1} className="boxSkills">
          <StaticImage
            src="../../images/team-leader.png"
            width={50}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Team Work"
            placeholder="tracedSVG"
          />
          <h6 className="skillsDescription">
            {t("home.experiencecards.teamwork")}
          </h6>
        </Box>
      </Grid>
    </div>
  )
}

export default SkillsExperience
