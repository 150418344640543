import * as React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Grid } from "@mui/material"
import "./WorkExperience.scss"

const WorkExperience = () => {
  const { t } = useTranslation()

  return (
    <div className="root">
      <Grid item xs={12} className="innerGrid">
        <Box mt={2} mb={2} sx={{ textAlign: "center" }}>
          <h2>{t("home.workexperiencetitle")}</h2>
        </Box>
      </Grid>
      <Grid container spacing={0} className="mainGridWorkXp">
        <Grid>&nbsp;</Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          spacing={2}
        >
          <Grid item className="innerGridWorkXp">
            <Box className="mainBoxWorkXp" align="center">
              <Box className="innerBoxWorkXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/workcompanies/shapya-icon.png"
                  height={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="ShapYa"
                  placeholder="blurred"
                  className="workXpImg"
                />
                <h6>ShapYa</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridWorkXp">
            <Box className="mainBoxWorkXp" align="center">
              <Box className="innerBoxWorkXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/workcompanies/favicon-e-quarks.png"
                  height={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="E-Quarks"
                  placeholder="blurred"
                  className="workXpImg"
                />
                <h6>E-Quarks</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridWorkXp">
            <Box className="mainBoxWorkXp" align="center">
              <Box className="innerBoxWorkXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/workcompanies/light_house_col.png"
                  height={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Light House Software and Training Col"
                  placeholder="blurred"
                  className="workXpImg"
                />
                <h6>Light House</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridWorkXp">
            <Box className="mainBoxWorkXp" align="center">
              <Box className="innerBoxWorkXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/workcompanies/celta_group.png"
                  height={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Celta Group"
                  placeholder="blurred"
                  className="workXpImg"
                />
                <h6>Celta Group</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridWorkXp">
            <Box className="mainBoxWorkXp" align="center">
              <Box className="innerBoxWorkXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/workcompanies/jppcode.png"
                  height={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="jppcode"
                  placeholder="blurred"
                  className="workXpImg"
                />
                <h6>jppcode</h6>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid>&nbsp;</Grid>
      </Grid>
    </div>
  )
}

export default WorkExperience
