import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PhotoDescription from "../components/home/PhotoDescription"
import YearsExperience from "../components/home/YearsExperience"
import ExperienceCards from "../components/home/ExperienceCards"
import SkillsExperience from "../components/home/SkillsExperience"
import TechnologiesExperience from "../components/home/TechnologiesExperience"
import WorkExperience from "../components/home/WorkExperience"
import BackToTop from "../components/BackToTop"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Julio Prada: Full Stack Developer"
      keywords={[
        "julio prada",
        "julio cesar prada martinez",
        "full stack developer",
        "fullstack developer",
        "full-stack developer",
        "frontend developer",
        "front-end developer",
        "front end developer",
        "web developer",
        "software developer",
        "web development",
        "software development",
        "programmer",
        "jcprada05",
        "economist",
      ]}
    />
    <PhotoDescription />
    <br />
    <YearsExperience />
    <ExperienceCards />
    <br />
    <SkillsExperience />
    <br />
    <br />
    <TechnologiesExperience />
    <br />
    <br />
    <WorkExperience />
    <BackToTop />
  </Layout>
)

export default IndexPage
