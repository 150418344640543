import * as React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Button, Dialog, Grid } from "@mui/material"
import "./PhotoDescription.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGithub,
  faGitlab,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"

const PhotoDescription = () => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={0}
        className="photoDescriptionMainGrid"
      >
        <Grid
          xs={12}
          sm={4}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Box>
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              onClick={handleClickOpen}
              style={{ backgroundColor: "transparent" }}
            >
              <StaticImage
                src="../../images/julioprada-icon.png"
                width={200}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Julio Prada Full Stack Developer"
                style={{
                  marginBottom: `1.45rem`,
                }}
                className="julioprada_photo"
              />
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <StaticImage
                src="../../images/julioprada-draw.jpg"
                maxWidth={600}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Julio Prada Draw in London"
                className="border_gradient julioprada_draw"
              />

              <span class="bottom-left">{t("home.drawdescription")}</span>
            </Dialog>
          </Box>
        </Grid>
        <Grid
          xs={12}
          sm={8}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="cardTitleSocialMedia"
        >
          <Box className="innerCardTitleSocialMedia">
            <Box className="cardTitle" sx={{ textAlign: "center" }}>
              <h2>{t("home.maincardtitle")}</h2>
            </Box>
            <Box mt={2} sx={{ textAlign: "center" }}>
              <a
                href="https://github.com/jcprada05"
                target="_blank"
                rel="noreferrer"
                className="iconSocialMedia"
              >
                <FontAwesomeIcon icon={faGithub} size="lg" />
              </a>
              <a
                href="https://gitlab.com/jcprada05"
                target="_blank"
                rel="noreferrer"
                className="iconSocialMedia"
              >
                <FontAwesomeIcon icon={faGitlab} size="lg" />
              </a>
              <a
                href="https://www.linkedin.com/in/jcprada05/"
                target="_blank"
                rel="noreferrer"
                className="iconSocialMedia"
              >
                <FontAwesomeIcon icon={faLinkedin} size="lg" />
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default PhotoDescription
