import * as React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import { Grid, Box, Hidden } from "@mui/material"
import "./ExperienceCards.scss"

const ExperienceCards = () => {
  const { t } = useTranslation()

  return (
    <div className="root">
      <Grid container spacing={0} align="center" className="mainGrid">
        <Grid item xs={12} className="innerGrid">
          <Box align="center" mt={2} mb={6}>
            <h2>{t("home.experiencecards.developing")}</h2>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} className="innerGrid">
          <Box className="mainBox" align="center">
            <Box className="innerBox" width="250px" height="250px">
              <StaticImage
                src="../../images/static-website-amico.png"
                width={180}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Websites Development"
                placeholder="blurred"
                style={{
                  marginBottom: `0.5rem`,
                }}
              />
              <h4>{t("home.experiencecards.websites")}</h4>
            </Box>
          </Box>
        </Grid>
        <Hidden smUp>
          <Grid item xs={12} className="innerGrid">
            <br />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={6} className="innerGrid">
          <Box className="mainBox" align="center">
            <Box className="innerBox" width="250px" height="250px">
              <StaticImage
                src="../../images/data-extraction-amico.png"
                width={180}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Websites Development"
                placeholder="blurred"
                style={{
                  marginBottom: `0.5rem`,
                }}
              />
              <h4>{t("home.experiencecards.webapplications")}</h4>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default ExperienceCards
