import * as React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Grid } from "@mui/material"
import "./TechnologiesExperience.scss"

const TechnologiesExperience = () => {
  const { t } = useTranslation()

  return (
    <div className="root">
      <Grid container spacing={0} className="mainGridTechsXp">
        <Grid item xs={12} className="innerGrid">
          <Box mt={2} mb={2} sx={{ textAlign: "center" }}>
            <h2>{t("home.technologiesexperiencetitle")}</h2>
          </Box>
        </Grid>

        <Grid>&nbsp;</Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          spacing={2}
        >
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/html5_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="HTML"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>HTML</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/css3_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="CSS"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>CSS</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/js_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="JavaScript"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>JavaScript</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/typescript_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="TypeScript"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>TypeScript</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/react_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="React"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>React</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/gatsby_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Gatsby"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>Gatsby</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/nextjs_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Next JS"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>Next JS</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/redux_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Redux Toolkit"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>Redux Toolkit</h6>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid>&nbsp;</Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          spacing={2}
        >
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/node_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Node JS"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>Node JS</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/php_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="php"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>php</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/symfony_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Symfony"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>Symfony</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/sql_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="SQL"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>SQL</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/mysql_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="MySQL"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>MySQL</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/postgresql_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="PostgreSQL"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>PostgreSQL</h6>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid>&nbsp;</Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          spacing={2}
        >
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/sass_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Sass"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>Sass</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/material-ui_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Material-UI"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>Material-UI</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/bootstrap_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Bootstrap"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>Bootstrap</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/styledcomponents_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="styled components"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>styled components</h6>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid>&nbsp;</Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          spacing={2}
        >
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/git_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Git"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>Git</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/github_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="GitHub"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>GitHub</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/gitlab_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="GitLab"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>GitLab</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/npm_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="npm"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>npm</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/yarn_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Yarn Package"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>yarn</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/composer_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="COMPOSER"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>COMPOSER</h6>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid>&nbsp;</Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          spacing={2}
        >
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/vscode_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Visual Studio Code"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>VSCode</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/postman_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Slack"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>POSTMAN</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/slack_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Slack"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>Slack</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/notion_logo_512.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Notion"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>Notion</h6>
              </Box>
            </Box>
          </Grid>
          <Grid item className="innerGridTechsXp">
            <Box className="mainBoxTechsXp" align="center">
              <Box className="innerBoxTechsXp" width="100px" height="100px">
                <StaticImage
                  src="../../images/technologies/clickup_logo.png"
                  width={50}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="ClickUp"
                  placeholder="blurred"
                  className="techsXpImg"
                />
                <h6>ClickUp</h6>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default TechnologiesExperience
