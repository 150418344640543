import * as React from "react"
import useScrollTrigger from "@mui/material/useScrollTrigger"
import Fade from "@mui/material/Fade"
import { IconButton } from "@mui/material"
import { KeyboardArrowUp } from "@mui/icons-material"
import scrollTo from "gatsby-plugin-smoothscroll"

const BackToTop = props => {
  const { window } = props

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  return (
    <>
      <Fade in={trigger}>
        <IconButton
          size="large"
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            background: "rgba(255, 255, 255, 0.25)",
            boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
            backdropFilter: "blur(4px)",
          }}
          onClick={() => {
            scrollTo("#julioPradaTop")
          }}
        >
          <KeyboardArrowUp fontSize="large" />
        </IconButton>
      </Fade>
    </>
  )
}

export default BackToTop
